.tox-tinymce {
  border: 1px solid #8c8c8c !important;
  border-radius: 5px !important;
}

.tox {
  .tox-toolbar__group:not(:last-of-type) {
    border-right: 1px solid #8c8c8c !important;
  }

  .tox-edit-area {
    border-top: 1px solid #8c8c8c !important;
  }

  // nested so it doesn't mess up other styles, none of it is namespaces
  @import '~tinymce/skins/content/default/content';
}

@import '~tinymce/skins/ui/oxide/skin';
