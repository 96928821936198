@import '~@dart-design/core/src/styles/variables';
// Before notie is imported:
$black: #000;
$white: #fff;
$notie-color-success: #5cb85e;
$notie-color-warning: #f7931e;
$notie-color-error: #da5350;
$notie-color-info: $white;
$notie-color-neutral: #a0a0a0;
$notie-color-overlay: $black;
$notie-z-index: 998;

$notie-content-width: auto;
// See all overwriteable variables in src/notie.scss
// Then import notie:
@import '../node_modules/notie/src/notie';

.notie-container {
  background-color: $white;
  direction: rtl;
  margin-top: 100px;
  text-align: center;

  @media (max-width: $screen-sm-min) {
    margin-top: 50px;
  }

  &.notie-background-error {
    background-color: $notie-color-error;
  }

  &.notie-background-warning {
    background-color: $notie-color-warning;
  }

  &.notie-background-success {
    background-color: $notie-color-success;
  }
}

.notie-background-info {
  .notie-textbox-inner {
    color: $black;
  }
}

.notie-textbox-inner {
  direction: ltr;
  max-width: 100%;
  padding: 0 12px;
  text-align: left;

  @media (min-width: $screen-sm-min) {
    width: $container-sm;
  }

  @media (min-width: $screen-md-min) {
    width: $container-md;
  }

  @media (min-width: $screen-lg-min) {
    width: $container-lg;
  }

  > *:first-child {
    margin-top: 0;
  }

  > *:last-child {
    margin-bottom: 0;
  }
}

.notie-button {
  border: 1px solid transparent;
  border-radius: 5px;
  font-weight: bold;
  text-transform: uppercase;

  &.notie-element-half {
    @media (min-width: $screen-sm-min) {
      margin: $space-m;
      max-width: 150px;
    }
  }

  &.notie-background-error {
    background-color: transparent;
    border-color: $brand-primary;
    border-width: 1px;
    color: inherit;
  }

  &.notie-background-success {
    background-color: $brand-primary;
    border-color: darken($brand-primary, 5%);
  }
}
