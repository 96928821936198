@mixin flexDirection($direction) {
  -webkit-flex-direction: $direction;
  -moz-flex-direction: $direction;
  flex-direction: $direction;
}
@mixin flexAlign($alignment) {
  -webkit-align-items: $alignment;
  align-items: $alignment;
}
@mixin flexJustify($spacing) {
  -webkit-justify-content: $spacing;
  -moz-justify-content: $spacing;
  justify-content: $spacing;
}

.flexible {
  display: -webkit-flex;
  display: flex;

  @include flexDirection(column);

  > * {
      width:auto;
  }

  .grows {
      -webkit-flex: 1 auto; /* Safari 6.1+ */
      -moz-flex: 1 auto;
      -ms-flex: 1 auto; /* IE 10 */
      flex: 1 auto;
  }

  &.vertical {
      @include flexDirection(column);
  }

  &.horizontal {
      @include flexDirection(row);
  }

  &.align-middle {
      @include flexAlign(center);
  }

  &.center {
      @include flexJustify(center);
      @include flexAlign(center);
  }

  &.align-center {
      @include flexJustify(center);
  }

  &.align-bottom{
      @include flexAlign(baseline);
  }

  &.align-start{
      @include flexAlign(start);
  }

  &.align-end{
      @include flexAlign(end);
  }

  &.fill{
      @include flexAlign(stretch);
  }

  @media (max-width: $screen-xs-max) {
      &.vertical-xs {
          @include flexDirection(column);
      }
      &.horizontal-xs {
          @include flexDirection(row);
      }
      &.align-middle-xs {
          @include flexAlign(center);
      }
      &.align-center-xs {
          @include flexJustify(center);
      }
  }
  @media (min-width: $screen-sm-min) {
      &.vertical-sm {
          @include flexDirection(column);
      }
      &.horizontal-sm {
          @include flexDirection(row);
      }
      &.align-middle-sm {
          @include flexAlign(center);
      }
      &.align-center-sm {
          @include flexJustify(center);
      }
  }
  @media (min-width: $screen-md-min) {
      &.vertical-md {
          @include flexDirection(column);
      }
      &.horizontal-md {
          @include flexDirection(row);
      }
      &.align-middle-md {
          @include flexAlign(center);
      }
      &.align-center-md {
          @include flexJustify(center);
      }
  }
  @media (min-width: $screen-lg-min) {
      &.vertical-lg {
          @include flexDirection(column);
      }
      &.horizontal-lg {
          @include flexDirection(row);
      }
      &.align-middle-lg {
          @include flexAlign(center);
      }
      &.align-center-lg {
          @include flexJustify(center);
      }
  }
}
