@import '~@dart-design/core/src/styles/variables';
@import '~@dart-design/core/src/styles/colors';
@import './flexible';

body {
  background-color: #ffffff;
}

.nav-menu {
  top: -500px;
  left: 1%;
  width: 98%;
  position: absolute;
}

.nav-menu-content {
  border-radius: 0 0 5px 5px;
  background-color: white;
  border: 1px solid #929292;
}

.nav-menu ul.nav-menu-list {
  list-style: none;
  padding: 0;
  margin: 10px;

  li {
    padding: 5px;
  }

  hr {
    margin: 5px 0;
  }
}

footer {
  margin-bottom: 15px;
  margin-top: 15px;
}

.list > .header {
  background-color: $brand-primary;
  border-radius: 3px 3px 0 0;
  color: $white;
  font-weight: bold;
  padding: 10px;

  &.bg-silver {
    background-color: $silver-base;
  }
}

.list {
  > .card {
    &.card-striped {
      margin-bottom: 0;

      &:nth-child(odd) { // sass-lint:disable-line nesting-depth
        background: $silver-light;
      }
    }
  }
}

table.request-details {
  tr td {
    line-height: 3rem;

    &:first-child {
      min-width: 25rem;
      vertical-align: top;
    }
  }
}

.react-select-container {
  .react-select__control { // sass-lint:disable-line class-name-format
    border: 1px solid #8c8c8c;
    min-height: 43px;
  }

  .react-select__indicator-separator { // sass-lint:disable-line class-name-format
    background-color: #404040;
  }

  .react-select__dropdown-indicator { // sass-lint:disable-line class-name-format
    color: #404040;
  }
}

.center-text {
  text-align: center;
}

.list-actions {
  .btn {
    margin-top: 0;
    padding: 0 5px;
  }
}

.card {
  .expanded-view {
    margin-top: 10px;
  }
}

.product-comments {
  .product-comments-icon {
    padding-right: 5px;
    width: 40px;
  }

  .product-comments-text {
    width: calc(100% - 50px);
  }
}

.svg-inline--fa {
  color: $dart-base;
}

.btn-primary {
  .svg-inline--fa {
    color: $white;
  }
}

.btn-link {
  text-transform: inherit;
  padding: inherit;
  margin: inherit;
  line-height: inherit;
}

.search-form {
  .btn {
    margin-top: 0;
  }

  label {
    display: none;

    ~ .form-control-feedback {
      top: 0;
    }
  }
}

.response-actions {
  display: flex;
  flex-shrink: 0;
  flex-wrap: nowrap;
  height: fit-content;

  .label {
    margin-right: 5px;
  }
}

.rich-text-display {
  table {
    border-radius: 5px 5px 5px 5px;
  }

  table td, th {
    padding: 0.4rem;
    border: 1px solid #ccc;
  }
}

.pagination .page-link {
  border-radius: 3px;
  margin: 0 5px;
}

mark {
  &.highlight {
    background-color: $teal-light;
  }
}

.btn-group {
  margin-left: 5px;
}

.btn {
  &.dropdown-toggle {
    .caret {
      margin-left: 0;
    }
  }
}

.dropdown-menu {
  li {
    button {
      clear: both;
      color: $black;
      display: block;
      font-weight: 400;
      line-height: 1.42857143;
      padding: 3px 20px;
      white-space: nowrap;
    }
  }
}
// make sure print styles overwrite all else.
@import './print';
