@media screen {
  .print-only {
    display: none;
  }
}

@media print {
  .container,
  .page-header,
  .card {
    border: 0;
    margin: 0;
    padding: 0;
  }

  header {
    display: none;
  }

  form {
    display: none;
  }

  h1,
  h2 {
    border: 0;
    margin: 10px 0;
    padding: 0;
  }

  h2 {
    page-break-before: always;
  }

  .nav {
    &.nav-tabs,
    a {
      display: none;
    }

    &.nav-tabs {
      border-bottom: 0;
    }
  }

  .btn {
    &.btn-primary,
    &.btn-secondary,
    &.btn-default,
    &.btn-transparent {
      display: none;
    }
  }

  p {
    break-inside: avoid;
    margin: 0;
    padding: 0;
    page-break-inside: avoid;
  }

  .question-card,
  .product-card {
    border-top: 1px solid $silver-base;
    margin: 0;
    padding: 10px 0;
  }

  .response-list {
    margin: 0 0 0 20px;
  }

  .card {
    box-shadow: none;

    &.response-card {
      background-color: transparent;
      border-left: 1px solid $silver-base;
      margin: 5px 0;
      padding: 0 0 0 10px;
    }
  }

  .row {
    .expanded-view {
      margin-top: 0;
    }
  }

  .print-hide {
    display: none;
  }

  .print-only {
    display: block;
  }

  .btn-link {
    color: $black;
  }

  .list {
    > .header {
      background-color: transparent;
      color: $black;
    }

    > .card {
      &.card-striped {
        &:nth-child(odd) {
          background-color: transparent;
        }
      }
    }
  }
}
